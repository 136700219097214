import React, { useState } from "react";
import { VStack, Button, Text, HStack, Tooltip } from "@chakra-ui/react";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import { truncateAddress } from "./util/utils.js";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import Web3 from 'web3'
import axios from 'axios';

function App() {
    const [account, setAccount] = useState();
    const [ethereum, setEthereum] = useState();
    const [type, setType] = useState();

    const ConnectWallet = async () => {
        const coinbaseWallet = new CoinbaseWalletSDK({
            appName: 'CBStay',
            appLogoUrl: 'app_logo.png',
            enableMobileWalletLink: true
        });

        let ethereum = coinbaseWallet.makeWeb3Provider()
        setEthereum(ethereum);
        const web3 = new Web3(ethereum)

        ethereum.request({ method: 'eth_requestAccounts' }).then(response => {
            const accounts = response
            console.log(`User's address is ${accounts[0]}`)
            setAccount(accounts[0]);

            const windowUrl = window.location.search;
            const params = new URLSearchParams(windowUrl);
            console.log(params)
            if (params.get('type') === 'connect_wallet') {
                axios({
                    method: "post",
                    url: "https://cbstay.com/wp-json/mobileapi/v1/coinbase_connects",
                    data: { account: accounts[0], user_id: params.get('user_id'), token: params.get('token') },
                    headers: { "Content-Type": "multipart/form-data" },
                }).then(function (response) {
                    alert('All Set! redirecting to app....');
                    window.location.href = "io.knoxweb.cbstay://?status=connected"
                }).catch(function (response) {
                    // window.location.href=window.location.href="?status=not_connected"
                    alert(response);
                });
            } else {
                setType(params.get('type'))
            }
            web3.eth.defaultAccount = accounts[0]
        })

        // ethereum.enable() is now deprecated in 4.0.0
        ethereum.enable().then((accounts) => {
            console.log(`User's address is ${accounts[0]}`)
            web3.eth.defaultAccount = accounts[0]
        })


    }

    const SendTransaction = async () => {
        ethereum.request({
            "method": "eth_sendTransaction",
            "params": [
                {
                    to: "0xfbE7A5e17568dBE7D1705307b5d1B59E458c768B",
                    from: account,
                    // "value": ethAmount.toString(),
                    // // "gas": "21000", // Standard gas limit for ETH transfer
                    // // "gasPrice": "20000000000", // Gas price in Wei (20 Gwei)
                    // // "nonce": "0"
                }
            ],
        }).then(res => {
            console.log(res)
            localStorage.setItem('setData', JSON.stringify(res));
        }, err => {
            localStorage.setItem('setDataError', JSON.stringify(err));
            console.log(err)
            alert(err.message)
        });
    }
    return (

        <VStack justifyContent="center" alignItems="center" h="100vh">
            <HStack marginBottom="10px">
                <Text
                    margin="0"
                    lineHeight="1.15"
                    fontSize={["1.5em", "2em", "3em", "4em"]}
                    fontWeight="600"
                >
                    Connect Coinbase Wallet with
                </Text>
                <Text
                    margin="0"
                    lineHeight="1.15"
                    fontSize={["1.5em", "2em", "3em", "4em"]}
                    fontWeight="600"
                    sx={{
                        background: "linear-gradient(90deg, #1652f0 0%, #b9cbfb 70.35%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent"
                    }}
                >
                    CB Stay App
                </Text>
            </HStack>

            {!account && <Button onClick={ConnectWallet} > Connect Wallet</Button>}
            {type && <Button onClick={SendTransaction} > Send Fund</Button>}
            {(account && !type) && <Button >Wallet Connected</Button>}

            <VStack justifyContent="center" alignItems="center" padding="10px 0">
                <HStack>
                    <Text>{`Connection Status: `}</Text>
                    {account ? (
                        <CheckCircleIcon color="green" />
                    ) : (
                        <WarningIcon color="#cd5700" />
                    )}
                </HStack>

                <Tooltip label={account} placement="right">
                    <Text>{`Account: ${truncateAddress(account)}`}</Text>
                </Tooltip>
            </VStack>
        </VStack>);
}
export default App;